import React, { CSSProperties, ReactChild } from 'react';
import { TooltipPopup, useTooltip } from '@reach/tooltip';

export const ChTooltip = ({
  label,
  style,
  children,
}: {
  label: string;
  style?: CSSProperties;
  children: ReactChild;
}) => {
  const [trigger, tooltip] = useTooltip();

  const myTrigger = {
    ...trigger,
    onMouseDown: () => undefined,
    onPointerDown: () => undefined,
  };

  return (
    <>
      {React.isValidElement(children)
        ? React.cloneElement(children, myTrigger)
        : { children }}
      <TooltipPopup {...tooltip} label={label} style={style}></TooltipPopup>
    </>
  );
};
