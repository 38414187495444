import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rhythm } from '../utils/typography';

const gradient = css`
  border-top: 2px solid rgba(179, 223, 240, 0.45);
  border-bottom: 2px solid rgba(179, 223, 240, 0.45);
  margin-top: ${rhythm(1 / 2)};
  padding: ${rhythm(1 / 2)};
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  text-align: center;
`;

export const ParagraphWithGradient = styled.p`
  ${gradient}
`;

export const DivWithGradient = styled.div`
  ${gradient}
`;
