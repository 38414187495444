import { valueof } from '../utils/typings';

export const PdcDefinitions = {
  vessel: `„Zbiornik” oznacza powłokę zaprojektowaną i zbudowaną w celu zawierania płynów pod ciśnieniem, wraz z elementami bezpośrednio przynależnymi, aż do miejsca połączenia z innym urządzeniem; zbiornik może się składać z więcej niż jednej przestrzeni ciśnieniowe.`,
  pipeline: `"Rurociągi” oznaczają części składowe instalacji rurowych przeznaczonych do transportu płynów, po połączeniu razem w zintegrowany układ ciśnieniowy; rurociągi obejmują w szczególności rury lub układ rur, kształtki rurowe, złączki, kompensatory, przewody elastyczne lub inne stosowne części składowe przenoszące ciśnienie; wymienniki ciepła składające się z rur do celów chłodzenia lub ogrzewania powietrza uznaje się za rurociągi`,
  protectorDevice: `„Osprzęt zabezpieczający” oznacza urządzenia, zaprojektowane w celu ochrony urządzeń ciśnieniowych przed przekraczaniem dopuszczalnych limitów, w tym urządzenia do bezpośredniego ograniczania ciśnienia, takie jak zawory bezpieczeństwa, urządzenia zabezpieczające w postaci przepon bezpieczeństwa, pręty wyboczeniowe, sterowane układy bezpieczeństwa do zrzutu ciśnienia (CSPRS), oraz urządzenia ograniczające, które uruchamiają środki korekcji albo umożliwiają wyłączenie lub wyłączenie i odcięcie obwodu, takie jak wyłączniki ciśnieniowe lub wyłączniki temperaturowe albo wyłączniki sterowane poziomem płynu, oraz zabezpieczające pomiarowe urządzenia sterujące i regulujące (SRMCR)`,
  pressureDevice: `"Osprzęt ciśnieniowy" oznacza urządzenia pełniące funkcje eksploatacyjne, posiadające powłoki ciśnieniowe`,
  ps: `„Najwyższe dopuszczalne ciśnienie PS” oznacza najwyższe ciśnienie, dla którego zaprojektowane jest urządzenie, określone przez producenta i zdefiniowane w miejscu przez niego określonym, którym jest miejsce przyłączenia urządzeń zabezpieczających lub ograniczających albo górna część urządzenia lub, jeśli nie jest to właściwe, dowolny określony punkt;`,
  ts: `„Jeśli prężność par medium w temperaturze projektowej urządzenia jest wyższa lub równa 0,5 barg (1,5 bar abs), to wybierz fazę gazową.

"Najwyższa lub najniższa dopuszczalna temperatura TS” oznacza najwyższe lub najniższe temperatury, dla jakich zostało zaprojektowane urządzenie, określone przez producenta;`,
  v: `„Pojemność (V)” oznacza wewnętrzną pojemność przestrzeni ciśnieniowej, w tym pojemność króćców do pierwszego podłączenia lub złącza spawanego, z wyłączeniem objętości stałych części wewnętrznych;`,
  dn: `„Wymiar nominalny (DN)” oznacza numeryczne oznaczenie wymiaru, który jest wspólny dla wszystkich części składowych w układzie rurociągu, innych niż części składowe określone przez średnice zewnętrzne lub rozmiar gwintów; jest to zaokrąglona liczba dogodna do celów porównawczych i jest jedynie luźno powiązana z wymiarami wykonawczymi; wymiar nominalny oznaczany jest symbolem „DN”, po którym następuje liczba;`,
  fluid: `„Płyny” oznaczają gazy, ciecze oraz pary w stanie czystym, jak również ich mieszaniny; płyny mogą zawierać zawiesinę ciał stałych;`,
  steamKettle: `"Kocioł parowy" rozumiany jako "kotły parowe o pojemności większej niż 2 dm3, przeznaczone do wytwarzania pary z cieczy z użyciem ciepła uzyskiwanego z paliwa w wyniku reakcji egzotermicznej lub z energii elektrycznej,"`,
  waterKettle: `"Kocioł cieczowy do podgrzewania wody" rozumiany jako "kotły cieczowe o pojemności większej niż 2 dm3, przeznaczone do podgrzewania wody (to podkreślić) bez zmiany jej stanu skupienia z użyciem ciepła uzyskiwanego z paliwa w wyniku reakcji egzotermicznej lub z energii elektrycznej, z wyjątkiem kotłów cieczowych w instalacjach systemu otwartego.`,
} as const;

export type PdcDefinitions = valueof<typeof PdcDefinitions>;
export type PdcDefinitionsKeys = keyof typeof PdcDefinitions;
