import { navigate } from 'gatsby';

export interface Route {
  label: string;
  title: string;
  url: string;
  language?: string[];
  children?: Route[];
  inMainMenu: boolean;
}

export function useRoutes() {
  return [
    {
      label: 'Kalkulatory Techniczne',
      title: 'CD Kalkulatory Techniczne',
      url: '/',
      inMainMenu: true,
      childern: [
        {
          label: 'kalkulatory Technicze: Zbiorniki Ciśnieniowe',
          title: 'CD Zbiorniki Ciśnieniowe',
          url: '/zbiorniki',
          inMainMenu: false,
        },
        {
          label: 'kalkulatory Technicze: Rurociągi Ciśnieniowe',
          title: 'CD Rurociągi',
          url: '/rurociagi',
          inMainMenu: false,
        },
        {
          label: 'kalkulatory Technicze: Osprzęt Ciśnieniowy',
          title: 'CD  Osprzęt Ciśnieniowy',
          url: '/osprzet_cisnieniowy',
          inMainMenu: false,
        },
        {
          label: 'kalkulatory Technicze: Urządzenie Zabezpieczające',
          title: 'CD  Urządzenie Zabezpieczające',
          url: '/urzadzenie_zabezpieczajace',
          inMainMenu: false,
        },
      ],
    },
  ];
}

export const navigateToCalculatorList = () => navigate(`/`);

export const navigateToCalculator = (calcName: string) => () =>
  navigate(`/${calcName}`);
